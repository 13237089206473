import React from 'react';
import { AppBar, Box, Container, Toolbar, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import { COLORS } from '@colors/index';

const useStyles = makeStyles(() => ({
    toolbar: {
        backgroundColor: COLORS.Primary
    }
}));

const Navbar: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Box>
            <AppBar position="fixed">
                <Toolbar disableGutters className={classes.toolbar}>
                    <Container>
                        <Grid container alignItems="center" mt={1}>
                            <Grid item xs={6}>
                                <Box component={NavLink} to="/">
                                    <Box component="img" src="/img/logos/logo.png" alt="logo" height={30} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
            <Toolbar>
                <Container>
                    <Box my={2} component="img" src="/img/logos/logo.png" alt="logo" height={55} />
                </Container>
            </Toolbar>
        </Box>
    );
};

export default Navbar;
