import React from 'react';
import Home from '@screens/Home';

export interface RoutesInterface {
    path: string;
    name: string;
    element: React.FunctionComponent;
}

const appRoutes: RoutesInterface[] = [
    {
        path: '/',
        name: 'Home',
        element: Home
    }
];

export default appRoutes;
