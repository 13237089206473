import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { COLORS } from '@colors/index';

const Footer: React.FunctionComponent = () => {
    return (
        <Box component="footer" p={4} bgcolor={COLORS.Gray}>
            <Container>
                <Box display="flex" minHeight={120} justifyContent="center" alignItems="center" textAlign="center">
                    <Typography variant="h6" color={COLORS.White}>
                        {`Copyright © ${new Date().getFullYear()}. LifeWith`}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
