import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from '@layouts/LandingPage';
import appRoutes from '@navigation/routes';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { COLORS } from '@colors/index';

const theme = createTheme({
    palette: {
        primary: {
            main: COLORS.Primary,
            dark: COLORS.PrimaryDark
        }
    },
    typography: {}
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingPage />}>
                        {appRoutes.map((prop, key) => {
                            return <Route path={prop.path} element={<prop.element />} key={key} />;
                        })}
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
