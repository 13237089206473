import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

const RoundedButton: React.FunctionComponent<ButtonProps> = (props) => {
    return (
        <Button variant="contained" color="primary" size="large" {...props} sx={{ borderRadius: 28, ...props.sx }}>
            {props.children}
        </Button>
    );
};

export default RoundedButton;
