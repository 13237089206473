export enum COLORS {
    Primary = '#ff725e',
    PrimaryDark = '#BD4D3D',
    White = '#FFFFFF',
    Blue = '#0E69CA',
    Black = '#000000',
    LightBlack = '#404040',
    Gray = '#2b2b2b',
    LightGray = '#E5E5E5'
}
