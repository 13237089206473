import React from 'react';
import { Box, Container, Stack, Typography, Link } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RoundedButton from '@components/Buttons/RoundedButton';
import { WaitListLink } from '@links/index';
import { COLORS } from '@colors/index';

const Home: React.FunctionComponent = () => {
    return (
        <Box minHeight='100vh'>
            <Box
                sx={{
                    backgroundImage: 'url(/img/home/background.png)',
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'fixed',
                    height: '100vh',
                    width: '100vw',
                    opacity: 0.2,
                    zIndex: -1
                }}
            />
            <Container maxWidth="md">
                <Stack direction="column" alignItems="center" textAlign="center" spacing={6}>
                    <Stack direction="column" alignItems="center" textAlign="center" spacing={2}>
                        <RocketLaunchIcon sx={{ fontSize: { xs: 100, md: 120 } }} />
                        <Typography variant="h2" sx={{ fontSize: { xs: 50, md: 60 } }} fontWeight={800}>
                            Coming Soon!
                        </Typography>
                        <Typography variant="h2" sx={{ fontSize: { xs: 20, md: 25 } }}>
                            Join our waitlist for early access to the app. We will let you know as soon as the LifeWith app launches and is ready to use!
                        </Typography>
                        <Typography variant="h2" sx={{ fontSize: { xs: 20, md: 25 } }}>
                            Organize important documents, keep notes, and track achievements in one place for free. LifeWith aims to help parents, care providers and organizations further progression.
                        </Typography>
                    </Stack>
                    <Link href={WaitListLink} target="_blank" style={{ textDecoration: 'none' }}>
                        <RoundedButton size="large" sx={{ color: COLORS.White, fontSize: { xs: 20, md: 25 }, paddingX: 5 }}>
                            JOIN WAITLIST
                        </RoundedButton>
                    </Link>
                    <Box component="img" src="/img/home/home.png" alt="home" width="100%" />
                </Stack>
            </Container>
        </Box>
    );
};
export default Home;
