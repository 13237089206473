import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

const LandingPage: React.FunctionComponent = () => {
    return (
        <Box>
            <CssBaseline />
            <Navbar />
            <Outlet />
            <Footer />
        </Box>
    );
};
export default LandingPage;
